<div mat-dialog-title class="tw-flex tw-items-center">
    @if (data.title) {
        <h2>{{ data.title | translate }}</h2>
    }
    <button tabindex="-1" class="tw-ml-auto tw-mb-auto" mat-icon-button aria-label="Close modal" [mat-dialog-close]="{action: 'cancel', data}">
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content class="tw-flex tw-flex-col tw-gap-4">
    @if (data.description) {
        <span [innerHTML]="data.description | translate"></span>
    }
    <ng-content></ng-content>
</mat-dialog-content>

<mat-dialog-actions [align]="'end'">
    @if (data.closeText) {
        <button mat-stroked-button color="primary" [mat-dialog-close]="{action: 'cancel', data}">
            {{ data.closeText | translate }}
        </button>
    }

    @if (data.submitText) {
        <button mat-flat-button color="primary" [mat-dialog-close]="{action: 'submit', data}" [ngClass]="{'tw-ml-3': data.closeText}">
            {{ data.submitText | translate }}
        </button>
    }
</mat-dialog-actions>
